<script lang="ts" setup>
const { t } = useI18n();

const localeRoute = useLocaleRoute();

const address = {
  companyName: "Helm & Walter IT-Solutions",
  street: "Räcknitzhöhe",
  number: "35a",
  zip: "01217",
  city: "Dresden",
  phone: "+49 351 799 035 0",
  mail: "info@helmundwalter.de",
};

const googleMapsUrl = computed(() => {
  const query = encodeURIComponent(
    `${address.companyName} ${address.street} ${address.number}, ${address.zip} ${address.city}`,
  );
  return `https://www.google.com/maps/search/?api=1&query=${query}`;
});
</script>

<template>
  <v-footer
    class="px-0 pt-0 pb-md-16 w-100 flex-column app-footer app-footer--saxony-ai"
  >
    <v-container class="pt-9">
      <v-row align="center" justify="center">
        <v-col cols="6" md="3">
          <nuxt-link :to="localeRoute('/')">
            <i-logo />
          </nuxt-link>
        </v-col>

        <v-col cols="12" md="1" />

        <v-col cols="11" md="7" style="white-space: pre-line">
          <p class="text-h3">{{ t("footer.headline") }}</p>
          <p class="pt-0">{{ t("footer.content") }}</p>
        </v-col>
      </v-row>

      <v-row justify="center" no-gutters>
        <v-col cols="10">
          <v-divider class="my-8 footer__divider" />
        </v-col>
      </v-row>

      <v-row align="stretch" class="text-center" no-gutters>
        <!-- Col 1-->
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="justify-lg-center d-flex flex-column"
        >
          <hw-button
            underline
            size="dense"
            class="text-none mx-auto"
            style="margin-bottom: 4px"
            :href="googleMapsUrl"
            target="_blank"
            width="fit-content"
          >
            {{ [address.street, address.number].join(" ") }}<br />
            {{ [address.zip, address.city].join(" ") }}
          </hw-button>

          <hw-button
            :href="`tel:${address.phone}`"
            underline
            size="dense"
            class="text-none mx-auto"
            width="fit-content"
          >
            {{ address.phone }}
          </hw-button>

          <hw-button
            :href="`mailto:${address.mail}`"
            underline
            size="dense"
            class="text-none mx-auto"
            width="fit-content"
          >
            {{ address.mail }}
          </hw-button>
        </v-col>

        <!-- Col 2-->
        <v-col
          cols="12"
          sm="6"
          md="8"
          class="justify-center d-flex flex-column footer__col-2"
        >
          <v-row no-gutters>
            <!-- Divider Col1/Col2-->
            <v-col class="d-sm-none" cols="10" offset="1">
              <v-divider class="hw-my-small footer__divider" />
            </v-col>

            <!-- Col2.1-->
            <v-col class="justify-center d-flex flex-column" cols="12" md="6">
              <hw-button
                :to="localeRoute({ name: 'about' })"
                underline
                size="dense"
                width="fit-content"
                class="mx-auto"
              >
                {{ t("navigation.about") }}
              </hw-button>

              <hw-button
                href="https://saxony.ai"
                underline
                size="dense"
                width="fit-content"
                class="mx-auto"
              >
                {{ t("navigation.ai") }}
              </hw-button>

              <hw-button
                :to="
                  localeRoute({
                    name: 'shopware',
                  })
                "
                underline
                size="dense"
                width="fit-content"
                class="mx-auto"
              >
                {{ t("navigation.shopware") }}
              </hw-button>
            </v-col>

            <!-- Divider Col2/Col3-->
            <v-col class="d-sm-none" cols="10" offset="1">
              <v-divider class="hw-my-small footer__divider" />
            </v-col>

            <!-- Col2.2-->
            <v-col class="justify-center d-flex flex-column" cols="12" md="6">
              <hw-button
                :to="localeRoute({ name: 'privacy' })"
                underline
                size="dense"
                width="fit-content"
                class="mx-auto"
              >
                {{ t("navigation.privacy") }}
              </hw-button>

              <hw-button
                :to="localeRoute({ name: 'imprint' })"
                underline
                size="dense"
                width="fit-content"
                class="mx-auto"
              >
                {{ t("navigation.imprint") }}
              </hw-button>

              <hw-button
                :to="localeRoute({ name: 'sitemap' })"
                underline
                size="dense"
                width="fit-content"
                class="mx-auto"
              >
                {{ t("sitemap.title") }}
              </hw-button>
              <v-spacer />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss">
@use "~/../_common/assets/scss/mixins";

.app-footer:deep(.v-btn) {
  user-select: text !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
}

.footer__col-2:deep(.v-btn) {
  @include mixins.media-query("sm-and-down") {
    height: calc(var(--v-btn-height) + 0px) !important;
    margin-bottom: 0.5rem;
  }
}

.footer__divider {
  max-width: 80%;
  margin-inline: auto;
  @include mixins.media-query("sm-and-down") {
    max-width: 90%;
  }
}
</style>
